import React from 'react';
// import { Helmet } from 'react-helmet';
// import styled from '@emotion/styled';

// import { Segment } from 'components/segment/Segment';

// const MessageWrapper = styled(Segment)`
//   align: center;
//   width: 100vw;
//   font-size: 2rem;
// `;

// tslint:disable no-default-export
export default () => {
  const message = "ceci n'est pas une page";

  return (
    <>
      {/* <Helmet title="Page Not Found" /> */}

      <h2>404</h2>
      <h1>{message}</h1>
      <div>
        <a href="https://www.kami.is/">🏠</a>
      </div>
    </>
  );
};
